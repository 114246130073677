import { html, LitElement } from "lit";

export default class SendViewComponent extends LitElement {
	static properties = {
		i18n: { type: Object },
		btnEnabled: { type: Boolean },
	};

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();
		this.btnEnabled = true;
		this.loginError = false;
		this.i18n = {
			sendTitle: "All data ready to send",
			sendSubTitle: "",
			sendText:
				"Your data are ready to send. Please click the button to finish",
			sendBtnText: "Send",
		};
	}

	resolve() {
		this.btnEnabled = false;

		this.dispatchEvent(
			new CustomEvent("send-resolved", {
				detail: "send",
				bubbles: true,
				composed: true,
			})
		);
	}

	render() {
		return html`
			<section class="hero is-fullheight">
				<div class="hero-body">
					<div class="container has-text-centered">
						<p class="title has-text-white">${this.i18n.sendTitle}</p>
						<p class="subtitle has-text-white">${this.i18n.sendSubTitle}</p>
						<p class="subtitle has-text-white white-space-pre-line">${this.i18n.sendText}</p>
						<button
							@click="${() => this.resolve()}"
							id="send-btn"
							class="button is-info ${!this.btnEnabled ? "is-loading" : ""}"
						>
							${this.i18n.sendBtnText}
						</button>
					</div>
				</div>
			</section>
		`;
	}
}

window.customElements.define("send-view", SendViewComponent);
