import { css, html, LitElement, render } from 'lit';

import '@vaadin/dialog';
import '@vaadin/button';
import '@vaadin/text-field';
import '@vaadin/password-field';
import '@vaadin/vertical-layout';

export default class AuthForm extends LitElement {

    static properties = {
        dialogOpened: { type: Boolean },
        i18n: { type: Object },
        customerNumber: { type: String },
        user: { type: String },
        password: { type: String },
        responsiveSteps: { type: Array },
        loginError: { type: Boolean }
    }

    constructor () {
        super();
        this.dialogOpened = false;
        this.loginError = false;
        this.i18n = {
            title: "Login Form",
            customerNumber: "CustomerID",
            user: "Username",
            password: "Password",
            loginButton: "Click to login",
            errorMessages: {
                customerId: "CustomerID must be exactly 7 digits",
                username: "Username is required",
                password: "Password is required",
                errorInvalidCredentials: "Authorization Error! Check your login details and try again"

            }
        }
        this.customerNumber = '';
        this.user = '';
        this.password = '';
        this.responsiveSteps = [
            { minWidth: 0, columns: 1 },
            { minWidth: '600px', columns: 2 }
        ];
    }

    static styles = css`
        :host {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }


    `;


    render () {
        return html`
            <vaadin-dialog
                    no-close-on-outside-click
                    no-close-on-esc

                    header-title="${ this.i18n.title }"
                    .opened="${ this.dialogOpened }"

                    @opened-changed="${ ( event ) => {
                        this.dialogOpened = event.detail.value;
                    } }"
                    .headerRenderer=""
                    .renderer="${ this.dialogRenderer }"
                   
            >
            </vaadin-dialog>
        `;
    }

    resolve () {

        this.dispatchEvent(
            new CustomEvent( "authlogin-resolved", {
                detail: { customerNumber: this.customerNumber, user: this.user, password: this.password },
                bubbles: true,
                composed: true,
            } )
        );
    }


    dialogRenderer = ( root ) => {

        const loginErrorMessage = html`<vaadin-login-overlay opened no-autofocus>
                <p slot="footer"
                   class="text-center has-text-danger">${ this.i18n.errorMessages.errorInvalidCredentials }</p>
            </vaadin-login-overlay>`;


        render( html`
            <style>
                .login-button {
                    width: 100%;
                    margin-top: 1em;
                }

                @media (min-width: 600px) {
                    .login-button {
                        width: auto;
                        margin-top: 1em;
                    }
                }
            </style>
            <vaadin-form-layout .responsiveSteps="${ this.responsiveSteps }">

                <vaadin-text-field class="spacing" required
                                   .value="${ this.customerNumber }"
                                   @value-changed="${ ( e ) => this.customerNumber = e.detail.value }"
                                   label="${ this.i18n.customerNumber }"
                                   pattern="\\d{7}"
                                   error-message="${ this.i18n.errorMessages.customerNumber }"></vaadin-text-field>
                <vaadin-text-field class="spacing" required
                                   .value="${ this.user }"
                                   @value-changed="${ ( e ) => this.user = e.detail.value }"
                                   label="${ this.i18n.user }"
                                   error-message="${ this.i18n.errorMessages.user }"></vaadin-text-field>
                <vaadin-password-field class="spacing" required
                                       .value="${ this.password }"
                                       @value-changed="${ ( e ) => this.password = e.detail.value }"
                                       label="${ this.i18n.password }"
                                       error-message="${ this.i18n.errorMessages.password }"></vaadin-password-field>


            </vaadin-form-layout>

            <vaadin-horizontal-layout theme="spacing padding " style="justify-content: center">
                <vaadin-button theme="primary large" id="login" class="login-button spacing"
                               @click="${ ( e ) => this.resolve( e ) }">
                    ${ this.i18n.loginButton }
                </vaadin-button>
            </vaadin-horizontal-layout>
            
            ${ this.loginError ? loginErrorMessage:""}
        `, root );
    };

    close () {
        this.dialogOpened = false;
    }
}

customElements.define( "dat-auth-form", AuthForm );
