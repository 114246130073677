/*jshint esversion: 11 */

import submitData from "../../lib/SubmitData";
import Dialog from "../../component/dialog/Dialog";
import { elem, taskId, TOKEN_UUID, validateToken } from "../../config";
import localforage from "localforage";
import "./SendViewComponent";
import "./SendComponent";


function SendView ( configuration ) {
    window.scrollTo( 0, 0 );

    return new Promise( async ( resolve, reject ) => {

        localforage.getItem( taskId ).then( async ( result ) => {

            const { env, withAuthorization } = result.config;

            const {
                translates,
                backgroundImage,
                component,
                topVisible = true,
                logoVisible = true,
                waveVisible = true,
            } = configuration;

            const { images, additionalSteps, steps, model, config } = result;

            let sendViewComponent = document.createElement( component );

            sendViewComponent.i18n = { ...translates };
            sendViewComponent.backgroundImage = backgroundImage;
            sendViewComponent.authRequired = withAuthorization !== undefined && !(await validateToken());

            sendViewComponent.config = {
                ...config,
                env: env,
                logo: result.config?.logoUrl,
                logoVisible: logoVisible,
                topVisible: topVisible,
                waveVisible: waveVisible,
                withAuthorization: withAuthorization
            };

            elem.innerHTML = "";
            elem.appendChild( sendViewComponent );


            window.addEventListener( "authorize-before-send", ( data ) => {
                localforage.setItem( taskId + TOKEN_UUID, data.detail )
                sendViewComponent.authRequired = false;
                sendViewComponent.btnEnabled = true;
            } );

            window.addEventListener( "send-resolved", async ( data ) => {
                try {

                    const response = await submitData( { images, additionalSteps, steps, model } );

                    if ( response.status === 200 ) {
                        resolve();
                    } else if ( response.status === 401 ) {

                            sendViewComponent.loginError = true;
                            sendViewComponent.authRequired = true;
                            sendViewComponent.btnEnabled = false;
                            sendViewComponent.requestUpdate()

                    } else {
                        sendViewComponent.btnEnabled = true;
                        Dialog( {
                                    title: translates?.errorTitle,
                                    message: translates.errorMessage,
                                    timeout: 4500,
                                } );
                    }
                } catch (error) {
                    sendViewComponent.btnEnabled = true;
                    Dialog( {
                                title: translates?.errorTitle,
                                message: translates.errorMessage,
                                timeout: 4500,
                            } );
                }
            }, { passive: true } );

        } );
    } );
}

export default SendView;
