export const ENV_PROD = "https://www.dat.de/AuthorizationManager/service--/endpoint/tokenService"
export const ENV_GOLD = "https://gold.dat.de/AuthorizationManager/service--/endpoint/tokenService"

const request = async ( URL, payload ) => {

    return await fetch( URL, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify( payload )
    } );

}
export const getDatToken = ( credentials, ENV = ENV_GOLD ) => {

    const { customerNumber, user, password } = credentials;

    const payload = {
        "action": "generateToken",
        "customerNumber": customerNumber,
        "user": user,
        "password": password

    };

    return request( ENV, payload );
}