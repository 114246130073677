import WeView from "../../component/basecomponent/WeView";
import '@vaadin/button';
import '@vaadin/progress-bar';
import '../../component/auth-form/AuthForm'
import { html } from "lit";
import { ENV_GOLD, ENV_PROD, getDatToken } from "../../service/DatAuthorizationService";


export default class SendComponent extends WeView {

    static properties = {
        authDialog: { type: Boolean },
        i18n: { type: Object },
        config: { type: Object },
        btnEnabled: { type: Boolean },
        backgroundImage: { type: String },
        authRequired: { type: Boolean },
        loginError: { type: Boolean }
    };

    constructor () {
        super();
        this.authDialog = false;
        this.config = {};
        this.i18n = {};
        this.btnEnabled = true;
        this.authRequired = false;
        this.loginError = false;
    }


    resolve () {
        this.btnEnabled = false;

        const event = new CustomEvent( "send-resolved", {
            detail: "send", bubbles: true, composed: true, cancelable: true
        } )

        this.dispatchEvent( event );

    }

    doLogin ( data ) {

        const ENV = (this.config?.env?.toUpperCase() === "PROD") ? ENV_PROD : ENV_GOLD;

        getDatToken( { ...data.detail }, ENV )
            .then( result => {
                result.json().then( token => {

                    const auth_event = new CustomEvent( "authorize-before-send", {
                        detail: token,
                        bubbles: true,
                        composed: true
                    } )

                    this.dispatchEvent( auth_event );
                } );
            } );
    }


    firstUpdated ( _changedProperties ) {
        super.firstUpdated( _changedProperties );

    }

    render () {

        const mailSection = html`<span class="icon-text">
			<span class="icon">
				<i class="fa-solid fa-at"></i>
			</span>
			<span>
                <a href="mailto:${this.i18n.mail}"  class="white-space-pre-line">${this.i18n.mail}</a>
            </span>
		</span>`;

        const callSection = html`<span class="icon-text">
			<span class="icon">
				<i class="fa-solid fa-phone" ></i>
			</span>
			<span>
                <a href="tel:${this.i18n.phone}" class="is-info white-space-pre-line">${this.i18n.phone}</a>
            </span>
		</span>`;

        return html`
            <style>

                .card {
                    height: 100%;
                }

                .card-content > .content {
                    margin-top: 1.5em;
                    margin-bottom: 3.5em;
                }


                .hero-body {
                    background-color: #f3f4f5;
                }

                .hero-with-logo {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                .hero-title {
                    min-width: 50%;
                }

                .button-is-native {
                    color: white;
                    background-color: #465064;
                    border-radius: 0px;
                    height: 2.8rem;
                }

                .is-we-grey {
                    color: #7c7c7c;
                }

                .logo-image {
                    display: flex;
                    min-width: 50%;
                    max-height: 5rem;;
                }

                .logo-image > img {
                    display: block;
                }

                .white-space-pre-line {
                    white-space: pre-line;
                }


                #svg4 {
                    width: 100%;
                    background-color: white;
                }

                .card-action {
                    padding: 1.5em;

                }

                @media screen and (max-width: 460px) {
                    .card-box {
                        padding-top: 1.5em;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }

                    .card-content {
                        margin-bottom: auto;
                    }

                    .on-mobile-fullwidth {
                        width: 100%;
                    }
                }

                @media screen and (min-width: 640px) {
                    .container {
                        max-width: 800px;
                    }

                    .card-box {
                        max-width: 640px;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        padding-top: 5em;
                    }

                    .card-action {
                        display: flex;
                        flex-direction: row;
                        justify-content: end;
                    }

                    .spacer {
                        width: 100%;;
                    }
                }


                .card-action {
                    margin-top: auto;
                }


                .content > .title {
                    padding-bottom: 1.5em;;
                }

                .container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                }

                .wave-is-hidden {
                    display: none;
                }


                .hero-body-is-white {
                    background-color: white;
                }

            </style>

            <div class="container">
                <section class="hero is-small " style=" ${ !this.config?.topVisible === true ? 'display: none' : '' }">
                    <div class="hero-body ${ !this.config.waveVisible === true ? 'hero-body-is-white' : '' }">
                        <div class="hero-with-logo">
                            <div class="hero-title">
                                <p class="title white-space-pre-line">${ this.i18n.heroTitle }</p>
                                <p class="subtitle white-space-pre-line">${ this.i18n.heroSubTitle }</p>
                            </div>
                            <div class="logo-image">
                                ${ this.config.logo && this.config.logoVisible === true ? html`<img
                                        src="${ this.config.logo }"
                                        alt="log"/>` : "" }
                            </div>
                        </div>
                    </div>

                    <svg style="width: 100%;" class="${ !this.config.waveVisible === true ? 'wave-is-hidden' : '' }"
                         viewBox="0 0 786.45166 71.022777"
                         version="1.1"
                         id="svg4"
                         sodipodi:docname="wave.svg"

                         inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
                         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:svg="http://www.w3.org/2000/svg">

                        <path
                                fill="#f3f4f5"
                                fill-opacity="1"
                                d="M 0,25.763344 43.691761,35.773477 C 87.383519,45.890956 174.76705,65.750204 262.15056,70.124601 349.53409,74.33798 436.91761,63.066519 524.30114,50.104337 611.68466,37.303175 699.06818,22.811294 742.75994,15.753211 L 786.45168,8.5877811 V 0 H 742.75994 C 699.06818,0 611.68466,0 524.30114,0 436.91761,0 349.53409,0 262.15056,0 174.76705,0 87.383519,0 43.691761,0 H 0 Z"
                                id="path2"
                                style="stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"/>
                    </svg>
                </section>
                <div class="card-box">
                    <div class="card-image"></div>
                    <div class="card-content card-stripe">
                        <div class="content">
                            <p class="title is-5 white-space-pre-line">${ this.i18n.title }</p>
                            <p class="white-space-pre-line">${ this.i18n.sendText }</p>
                            <p class="white-space-pre-line">${this.i18n.finishText}</p>
                            <p>${this.i18n?.mail?.length > 0 ? mailSection : ""}</p>
                            <p>${this.i18n?.phone?.length > 0 ? callSection : ""}</p>
                        </div>
                    </div>
                    

                    <dat-auth-form .dialogOpened="${ this.authRequired }" 
                                   .i18n="${ this.i18n?.authForm }"
                                   .loginError="${this.loginError}"
                                   @authlogin-resolved="${ ( e ) => this.doLogin( e ) }">
                            
                    </dat-auth-form>

                    ${ !this.btnEnabled ? html`
                        <div style="padding-left: 1.5em; padding-right: 1.5em; font-family: var(--lumo-font-family); color: var(--lumo-secondary-text-color);">
                            <div>${ this.i18n.sendingData }</div>
                            <vaadin-progress-bar indeterminate></vaadin-progress-bar>
                            <div style="font-size: var(--lumo-font-size-xs) white-space-pre-line">
                                ${ this.i18n.bePatient }
                            </div>
                        </div>` : "" }
                    <div class="card-action">
                        <vaadin-button theme="primary large" style="width: 100%"
                                       @click="${ () => this.resolve() }"
                                       ?disabled="${ !this.btnEnabled }"
                        >
                            ${ this.i18n.sendBtnText }
                        </vaadin-button>
                    </div>
                </div>

            </div>
        `;
    }
}

window.customElements.define( "send-component", SendComponent );
