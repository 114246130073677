/*jshint esversion: 11 */

/**
 * Convert component JSON to valid FotoHub Payload
 *
 * @param json
 * @returns {{images: *[], claim}}
 * @constructor
 */
const PreparePayload = (json) => {

  const { images, model = {} } = json;

  return {
    images: images,
    claim: model
  };
};

export default PreparePayload;
